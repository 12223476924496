footer p {
   width: 100%;
   height: 12%;
   margin-top: 20px;
   margin-bottom: 40px;
   padding-top: 50px;
   font-size: .7em;
   text-align: center;
   position: relative;
   display: inline-flex;
   justify-content: space-around;
}
 
footer a {
   margin-top: 10px;
   margin-bottom: 0px;
}
