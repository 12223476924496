/* Root Settings */
:root {
  --title: #007bff;
  --sub-title: #D65DB1;
  --bg: #000 !important;
  --txt: #A7A7A7;
  --white: #ffffff;
  --link-hover-bg: rgba(0, 0, 0, 0.5);
  --input-border: rgba(255, 255, 255, 0.2);
  --input-bg: rgba(255, 255, 255, 0.1);
  --form-input-bg: rgba(255, 255, 255, 0.15);
  --transition-duration: all 0.3399s;
  background-color: var(--bg);
  /*-webkit-transition: all 0.3s !important;
  transition: all 0.3s !important;*/
}

.App {
  text-align: center;
}

body {
  margin: 120px auto;
  /*padding-left: 5.6em;*/
  height: auto;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--txt);
  font-family: 'Open Sans', sans-serif;
  display: flex;
  justify-content: center;
}

.container {
  padding: 0 30px;
  position: relative;
}

.navHeight {
  margin: 5rem auto 5rem auto;
  font-size: 5vw;
}

.navHeight .title {
  font-size: 5rem;
  text-decoration: none;
}

.title a {
  color: var(--title);
  font-weight: bold;
  text-decoration: none;
}

svg {
  color: var(--sub-title);
  animation: 1.3s blinking step-end infinite;
  -webkit-animation: 1.3s blinking step-end infinite;
  -moz-animation: 1.3s blinking step-end infinite;
  -ms-animation: 1.3s blinking step-end infinite;
  -o-animation: 1.3s blinking step-end infinite;
  animation: 1.3s blinking step-end infinite;
}

@keyframes blinking {
  0% {color: var(--sub-title);}
  49% {color: var(--sub-title);}
  60% {color: transparent;}
  99% {color:transparent;}
  100% {color: var(--sub-title);}
}

a {
  color: var(--txt);
  text-decoration: none !important;
  cursor: pointer;
}

html {
  scrollbar-color: var(--txt) transparent !important;
  scrollbar-width: thin !important;
}

.sub-title {
  color: var(--sub-title);
}

.grid-container {
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 10px 10px;
  grid-template-areas:
    ". ."
    ". ."
    ". .";
  flex-wrap: wrap;
}

div.services h2 {
	color: var(--sub-title);
	font-size: 1.5rem;
	text-transform: uppercase;
	position: relative;
}

div.services ul {
	list-style: none;
	/*padding-left: 0;*/
}

ul.links a, ul.contacts a {
	color: #2e6da4;
	text-decoration: none;
}

ul.links a:hover, ul.contacts a:hover {
	color: #ccc;
	text-decoration: underline;
}

div ul.about li a {
	color: orange;
	font-weight: bold;
	text-decoration: none;
}

div ul.about li a:hover {
	color: #ccc;
	text-decoration: underline;
}

.form-container {
  background-color: #303030;
}

.form-container .sub-title {
  padding-top: 3.2rem;
  text-decoration: underline;
}

/* Form inputs */
* input,
* textarea,
* select {
  outline: none !important;
  margin: 10px;
  padding: 10px;
  border-radius: 7px;
}

* textarea {
  resize: none !important;
}

* button {
  border-color: transparent;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.3s ease;
  outline: none !important;
  color: var(--txt) !important;
  text-transform: uppercase !important;
  cursor: pointer;
}

* button:active {
  -webkit-transform: scale(0.95) !important;
  transform: scale(1) !important;
  -webkit-transition: var(--transition-duration);
  transition: var(--transition-duration);
}

* button:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: var(--transition-duration);
  transition: var(--transition-duration);
  color: var(--white) !important;
  background-color: var(--sub-title);
  padding: 0.4rem 4.5rem;
  border-radius: 0.3rem;
}

* textarea {
  resize: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-button {
  background-color: transparent;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: var(--main-bg);
  cursor: pointer;
}

::-webkit-scrollbar-track-piece {
  background-color: var(--main-bg);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-button {
  background-color: transparent;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: var(--main-bg);
  cursor: pointer;
}

::-webkit-scrollbar-track-piece {
  background-color: var(--main-bg);
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scroll-thumb);
  cursor: pointer;
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 426px) {
  body {
    margin: 50px -9px;
    padding: auto;
    padding-left: -7px;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    /*color: var(--txt);
    justify-content: center;*/
  }
  .navHeight {
    margin: 4px;
    padding: 3px;
  }
  .navHeight .title {
    font-size: 2.1rem;
    color: --blue;
    text-decoration: none;
  }
  .sub-title {
    margin-bottom: -5px;
    font-size: 1.4rem;
  }
  div.services h2 {
    margin: 10px -5px;
    padding: 10px 25px;
    color: #D65DB1;
    font-size: 1.4rem;
    text-transform: uppercase;
    position: relative;
    text-align: center;
  }
  .grid-container {
    height: 330px;
    display: inline-block;
    /*grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    grid-template-areas:
      ". ."
      ". ."
      ". .";*/
    flex-wrap: wrap;
  }
  .form-container .sub-title {
    padding-top: 2.2rem;
    padding: auto;
    text-decoration: underline;
  }
  /* Form inputs */
* input,
* textarea,
* select {
  outline: none !important;
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  }
  footer p {
    display: block;
    justify-content: center;
    padding-left: 8px;
  }
}
